<script setup lang="ts">
import {IValidateInput} from "~/composables/Validate/Interfaces/IValidateInput";
import {IValidateForm} from "~/composables/Validate/Interfaces/IValidateForm";

import {useValidateStore} from "~/store/validate/useValidateStore";
import {computed, onMounted, onUnmounted, watch} from "vue";

const {setInput, removeInput, updateInput} = useValidateStore();

defineOptions({
    name: 'component-AToggleSwitch'
});

const model = defineModel('value', {type: Boolean});

const props = defineProps({
    id: {
        type: String,
        default: 'default-switcher-id'
    },
    name: {
        type: String,
        default: 'a-toggle-switch',
    },
    customClass: {
        type: String,
        default: '' //todo size ['toggle-lg', 'toggle-sm', '' => standart-size], color ['toggle-secondary', 'toggle-success', 'toggle-info', 'toggle-dark', 'toggle-warning' .etc]
    },
    isModelValue: {
        type: Boolean, //if not v-model, listen "changeEvent"
        default: true
    },
    isDisabled: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        default: ''
    },
    formKey: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: 'text'
    },
    rules: {
        type: Array,
        default: [],
    },
});

watch(model, (newVal, oldValue) =>  {
    if (newVal !== oldValue) {
        props.formKey && updateInput(props.formKey, props.id, newVal);
    }
});

onMounted((): void => {
    props.formKey && setInput(props.formKey, props.id, props.name, model.value, props.type, props.rules);
});

onUnmounted((): void => {
    props.formKey && removeInput(props.formKey, props.id);
});

const field = computed((): IValidateInput|null => {
    let forms: IValidateForm[]|[] = useValidateStore().getForms;
    let form: IValidateForm|undefined = forms.find((form: IValidateForm): any => form.key === props.formKey);
    if (!form) {
        return null;
    }

    return <IValidateInput|null> form.inputs.find((input: IValidateInput): any => input.id === props.id);
});
</script>

<template>
    <div
        class="a-toggle-switcher d-flex align-items-center flex-wrap mb-3"
        :class="{'at-form-item_error': field?.error }"
        @click="model = !model"
    >
        <div
            class="toggle"
            :class="[props.customClass, { 'on': model, 'disable-switch': props.isDisabled }]"
        >
            <span></span>
        </div>

        <div
            v-if="props.label"
            class="a-toggle-switcher__label"
        >
            <slot name="label" :label="props.label">
                <p class="text-muted m-0">{{ props.label }}</p>
            </slot>

        </div>
    </div>
</template>
